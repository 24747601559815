// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CloseIcon from '@mui/icons-material/Close';
import PdfFileViewer from '../../components/FileViewer/PdfFileViewer';
import Modal from '@mui/material/Modal';
import ModalBox from 'components/ModalBox';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';

type Props = {
  files: Array,
  patientId: String,
  total?: number,
  page: number,
  limit: number,
  onPageChange: (number) => void,
  onLimitChange: (number) => void
};

const renderFileName = (filename) => {
  const MAX_CHARS = 100;
  if (!filename) return 'null';
  if (filename.length <= MAX_CHARS) return filename;
  const extension = filename.slice(filename.lastIndexOf('.'));
  const truncatedName = filename.slice(0, MAX_CHARS - extension.length - 3);

  return truncatedName + '...' + extension;
};

const ChartFilesTable = ({
  files,
  patientId,
  total = 0,
  page,
  limit,
  onPageChange,
  onLimitChange
}: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const sortBy = useMemo(() => searchParams.get('sortBy'), [searchParams]);
  const sortByOrder = useMemo(
    () => searchParams.get('sortByOrder'),
    [searchParams]
  );
  const [pdfViewerSrc, setPdfViewerSrc] = useState(null);
  const paramFile = searchParams.get('file');

  const navigate = useNavigate();
  const location = useLocation();

  const isPdf = (row) => {
    return (
      row.hasConvertedDocument ||
      (row.contentType === 'application/pdf' && row.hasOriginalDocument)
    );
  };

  const handleCloseFileViewer = () => {
    navigate(
      {
        search: new URLSearchParams({
          ...queryString.parse(location.search),
          fileHash: '',
          previewPage: '',
          search: ''
        }).toString()
      },
      { replace: true }
    );

    setPdfViewerSrc(null);
  };

  const handleOpenFilterViewer = (hash) => {
    navigate(
      {
        search: new URLSearchParams({
          ...queryString.parse(location.search),
          fileHash: hash
        }).toString()
      },
      { replace: true }
    );

    setPdfViewerSrc({
      sourceLocation: {
        hash
      }
    });
  };

  const handleChangePage = (newPage) => {
    onPageChange(newPage);
  };

  const handleChangeRowsLimit = (newLimit) => {
    onLimitChange(newLimit);
    onPageChange(1);
  };

  const columns = [
    {
      id: 'name',
      name: 'File Name',
      selector: (row) => renderFileName(row.name),
      sortable: true,
      minWidth: '500px',
      wrap: true
    },
    {
      id: 'documentType',
      name: 'Document Type',
      selector: (row) => row.documentType,
      sortable: true,
      wrap: true
    },
    {
      id: 'organization',
      name: 'Organization Name',
      selector: (row) => row.organization,
      sortable: true,
      wrap: true
    },
    {
      id: 'authorSoftware',
      name: 'Author Sofware',
      selector: (row) => row.authorSoftware,
      sortable: true,
      wrap: true
    },
    {
      id: 'serviceEventDt',
      name: 'Service Date',
      selector: (row) => row.serviceEventDt,
      width: '120px',
      sortable: true,
      sortFunction: (a, b) =>
        dayjs(a?.serviceEventDt).isAfter(dayjs(b?.serviceEventDt)) ? 1 : -1,
      wrap: true
    },
    {
      id: 'lastAnalyzedDt',
      name: 'Last analyzed',
      selector: (row) => row.lastAnalyzedDt,
      width: '120px',
      sortable: true,
      sortFunction: (a, b) =>
        dayjs(a?.lastAnalyzedDt).isAfter(dayjs(b?.lastAnalyzedDt)) ? 1 : -1,
      wrap: true
    },
    {
      name: '',
      selector: (row) => row.viewPdf,
      sortable: false,
      wrap: true,
      width: '80px',
      cell: (row) => {
        return (
          <div className="flex justify-center w-full">
            {isPdf(row) && (
              <Button
                onClick={() => handleOpenFilterViewer(row?.hash)}
                target="_blank"
                rel="noreferrer"
                className="!text-mineShaft/80 !text-[13px]"
              >
                PDF <LaunchIcon className="!text-[13px] ml-0.5" />
              </Button>
            )}
          </div>
        );
      }
    },
    {
      name: '',
      selector: (row) => row.cdaUrl,
      sortable: false,
      wrap: true,
      width: '80px',
      cell: (row) => {
        return (
          <>
            {row.cdaUrl ? (
              <Button
                component="button"
                href={row.cdaUrl}
                target="_blank"
                rel="noreferrer"
                className="!text-mineShaft/80 !text-[13px]"
              >
                XML <LaunchIcon className="!text-[13px] ml-0.5" />
              </Button>
            ) : null}
          </>
        );
      }
    },
    {
      id: 'createdDt',
      name: 'Date added',
      selector: (row) => row.createdDt,
      sortable: true,
      wrap: true,
      format: (row) => dayjs(row.createdDt).format('MMM DD YYYY hh:mmA')
    }
  ];

  useEffect(() => {
    const fileHash = searchParams.get('fileHash');

    if (fileHash) {
      setPdfViewerSrc({
        sourceLocation: {
          hash: fileHash
        }
      });
    }
  }, [paramFile, files]);

  return (
    <div className="mt-5">
      <DataTable
        columns={columns}
        keyField="hash"
        data={files}
        persistTableHead
        responsive
        highlightOnHover
        defaultSortAsc={sortByOrder === 'asc'}
        defaultSortFieldId={sortBy}
        noHeader
        pagination
        paginationServer
        paginationTotalRows={total}
        paginationPerPage={limit}
        paginationDefaultPage={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsLimit}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
      {pdfViewerSrc && (
        <Modal
          open={pdfViewerSrc}
          onClose={() => handleCloseFileViewer()}
          aria-labelledby="pdf-viewer-modal"
          aria-describedby="pdf-viewer-modal"
        >
          <ModalBox
            closeButton={
              <Button
                className="!absolute right-2 top-2 !text-red-600"
                onClick={() => handleCloseFileViewer()}
              >
                <CloseIcon />
              </Button>
            }
          >
            <PdfFileViewer
              closeModal={() => handleCloseFileViewer()}
              source={pdfViewerSrc}
              patientId={patientId}
            />
          </ModalBox>
        </Modal>
      )}
    </div>
  );
};

export default ChartFilesTable;
