import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

export const STACKS = {
  EHR: '/ehr',
  EHR2: '/ehr2',
  anonymization: '/anonymization',
  adminAutomation: '/admin-automation',
  batch: '/batch',
  benchmarks: '/benchmarks',
  fileManagement: '/file-management',
  hal: '/hal',
  icd: '/icd',
  organization: '/organization',
  patients: '/patients',
  queries: '/queries',
  reciprocityLogs: '/reciprocity-logs',
  userManagement: '/user-management'
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}`,
    prepareHeaders: async (headers) => {
      const jwtToken = await getNewJWTToken();
      headers.set('Content-Type', 'application/json');
      headers.set('authorization', `Bearer ${jwtToken ? jwtToken : ''}`);
      if (process.env.REACT_APP_ENV === 'dev') {
        headers.set('x-mdp-email', 'test@email.com');
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => `${STACKS.userManagement}/user`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    getOrganizations: builder.query({
      query: () => `${STACKS.userManagement}/organization/all`,
      transformResponse: (response) => {
        const { organizations, currentOrganizationId } = JSON.parse(
          response.body
        );
        if (response.statusCode !== 200) throw new Error(response.body);
        const currentOrganization = organizations.find(
          (organization) => organization._id === currentOrganizationId
        );

        return { organizations, currentOrganization };
      }
    }),
    getPatientHCCs: builder.query({
      query: ({ patientId, searchString }) =>
        `${STACKS.patients}/patient/${patientId}/hcc${searchString}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),

    getPatientQAHCCs: builder.query({
      query: ({ patientId }) =>
        `${STACKS.patients}/patient/${patientId}/qa/hcc`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),

    updateSourceFeedback: builder.mutation({
      query: ({ sourceId, agree, reason }) => ({
        url: `${STACKS.patients}/patient/source/${sourceId}/feedback`,
        method: 'POST',
        body: {
          agree,
          reason
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body).success || false;
      }
    }),
    searchIcds: builder.query({
      query: ({ searchValue, cmsVersion }) =>
        `${STACKS.icd}/icds?q=${searchValue}&cmsVersion=${cmsVersion}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    searchCiInputs: builder.query({
      query: ({ searchValue, ciInputField }) =>
        `${STACKS.EHR}/searchCiInputs?q=${searchValue}&ciInputField=${ciInputField}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    addPatientIcd: builder.mutation({
      query: ({ patientId, body }) => ({
        url: `${STACKS.icd}/patient/${patientId}/add-icd`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    addSourceToPatientHcc: builder.mutation({
      query: ({ body }) => ({
        url: `${STACKS.EHR}/addSourceToPatientHcc`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    generatePatientSummary: builder.query({
      query: (patientId) =>
        `${STACKS.patients}/generatePatientSummary/${patientId}`,
      transformResponse: (response) => {
        console.log('[generatePatientSummary] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).summaryText;
      }
    }),
    getIsUserHalBenchmarkOrg: builder.query({
      query: (userId) =>
        `${STACKS.userManagement}/getIsUserHalBenchmarkOrg/${userId}`,
      transformResponse: (response) => {
        console.log('[getIsUserHalBenchmarkOrg] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body);
      }
    }),
    addUserClinicConsent: builder.mutation({
      query: ({ body }) => ({
        url: `${STACKS.EHR}/userClinicConsent`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    getPatientCareGaps: builder.query({
      query: (patientId) => `${STACKS.patients}/patient/${patientId}/care-gaps`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    updateCareGaps: builder.mutation({
      query: ({ patientId, careGapId, status, note }) => ({
        url: `${STACKS.patients}/patient/${patientId}/care-gaps/update`,
        method: 'POST',
        body: {
          careGapId,
          status,
          note
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body) || {};
      }
    }),
    getBenchmarkEvidenceForPatient: builder.mutation({
      query: ({ patientId, searchTerm }) => ({
        url: `${STACKS.hal}/getHalBenchmarkEvidenceForPatient/${patientId}`,
        method: 'POST',
        body: {
          searchTerm
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body).evidence;
      }
    }),
    getPatientChartFiles: builder.query({
      query: ({ patientId, searchString }) => {
        const queryParams = searchString ? `?${searchString}` : '';
        return `${STACKS.patients}/patient/${patientId}/document${queryParams}`;
      },
      transformResponse: (response) => {
        const parsed = JSON.parse(response.body);
        return {
          data: parsed.data || [],
          total: parsed.total || 0
        };
      }
    }),
    uploadChartFiles: builder.mutation({
      query: ({ patientId, filename }) => ({
        url: `${STACKS.EHR2}/document/upload-url`,
        method: 'POST',
        body: { filename, patientId }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    uploadToPatient: builder.mutation({
      query: ({ fileIds }) => ({
        url: `${STACKS.EHR2}/document/enqueue-attempt-check-uploaded-chart-document`,
        method: 'POST',
        body: { fileIds }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    tryGetNewChartFiles: builder.mutation({
      query: ({ patientId, filenames }) => ({
        url: `${STACKS.EHR2}/document/chart-documents-exist`,
        method: 'POST',
        body: { patientId, filenames }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),

    confirmQAIcd: builder.mutation({
      query: ({ patientId, hccId }) => ({
        url: `${STACKS.patients}/patient/${patientId}/qa/confirm-icd`,
        method: 'POST',
        body: { hccId }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),

    denyQAIcd: builder.mutation({
      query: ({ patientId, hccId, reason }) => ({
        url: `${STACKS.patients}/patient/${patientId}/qa/deny-icd`,
        method: 'POST',
        body: { hccId, reason }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),

    getQAIcdSources: builder.query({
      query: ({ patientId, hccId }) =>
        `${STACKS.patients}/patient/${patientId}/qa/sources?icdCode=${hccId}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),

    getQASourceHistory: builder.query({
      query: ({ patientId, hccId, factName }) =>
        `${STACKS.patients}/patient/${patientId}/qa/sources/history?icdCode=${hccId}&factName=${factName}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    })
  })
});

// Export hooks for usage in functional components
export const {
  useLazyGetUserQuery,
  useUpdateSourceFeedbackMutation,
  useGetOrganizationsQuery,
  useGetPatientHCCsQuery,
  useGetPatientQAHCCsQuery,
  useLazyGetPatientQAHCCsQuery,
  useLazyGetPatientHCCsQuery,
  useLazySearchIcdsQuery,
  useLazySearchCiInputsQuery,
  useAddPatientIcdMutation,
  useAddSourceToPatientHccMutation,
  useLazyGeneratePatientSummaryQuery,
  useGetIsUserHalBenchmarkOrgQuery,
  useLazyGetIsUserHalBenchmarkOrgQuery,
  useAddUserClinicConsentMutation,
  useGetPatientCareGapsQuery,
  useUpdateCareGapsMutation,
  useGetBenchmarkEvidenceForPatientMutation,
  useGetPatientChartFilesQuery,
  useUploadChartFilesMutation,
  useUploadToPatientMutation,
  useTryGetNewChartFilesMutation,
  useConfirmQAIcdMutation,
  useDenyQAIcdMutation,
  useGetQAIcdSourcesQuery,
  useLazyGetQAIcdSourcesQuery,
  useGetQASourceHistoryQuery,
  useLazyGetQASourceHistoryQuery
} = apiSlice;

// addSourceToPatientHcc
