// @flow

import React, { useEffect, useState } from 'react';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlined from '@mui/icons-material/ThumbDownOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useUpdateSourceFeedbackMutation } from 'api/api';
import { useDispatch } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import {
  CircularProgress,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

type Props = {
  sourceId: String,
  userFeedback: Object
};

const SourceFeedback = ({ sourceId, userFeedback = {} }: Props) => {
  const { agree: agreeParam, reason: reasonParam = '' } = userFeedback;
  const dispatch = useDispatch();
  const [updateSourceFeedback, { isLoading }] =
    useUpdateSourceFeedbackMutation();
  const [agree, setAgree] = useState(agreeParam);
  const [selectedReason, setSelectedReason] = useState(reasonParam);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [reasonInput, setReasonInput] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAgree = () => {
    const agreeValue = agree === true ? null : true;
    handleSubmit(agreeValue, '');
  };

  const handleDisagree = (e) => {
    if (agree === false) {
      setAgree(null);
      handleSubmit(null, '');
    } else {
      setDialogOpen(true);
    }
  };

  const handleSubmit = (agreeVal, reason) => {
    updateSourceFeedback({ sourceId, agree: agreeVal, reason })
      .unwrap()
      .then((success) => {
        if (success) {
          setAgree(agreeVal);
          setSelectedReason(reason);
          dispatch(
            showToastMsg({
              open: true,
              message: 'Feedback updated successfully',
              level: 'success',
              duration: 5000
            })
          );
        } else {
          throw new Error(success);
        }
      })
      .catch((error) => {
        dispatch(
          showToastMsg({
            open: true,
            message: error?.message ?? 'update failed',
            level: 'error',
            duration: 5000
          })
        );
      });
  };

  const handleSaveReason = () => {
    handleSubmit(false, reasonInput);
    setSelectedReason(reasonInput);
    setDialogOpen(false);
    setReasonInput('');
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setReasonInput('');
  };

  useEffect(() => {
    if (agreeParam || reasonParam) {
      console.log('feedback', userFeedback);

      setAgree(agreeParam);
      setSelectedReason(reasonParam);
    }
  }, [agreeParam, reasonParam]);

  return (
    <div className="w-full">
      <Stack direction="row" spacing={0} justifyContent="end">
        {agree !== false && (
          <IconButton className="!text-mineShaft/40" onClick={handleAgree}>
            {agree ? (
              <ThumbUp color={isLoading ? 'disabled' : 'success'} />
            ) : (
              <ThumbUpOutlined />
            )}
          </IconButton>
        )}
        {agree !== true && (
          <IconButton className="!text-mineShaft/40" onClick={handleDisagree}>
            {agree === false && selectedReason ? (
              <ThumbDown color={isLoading ? 'disabled' : 'error'} />
            ) : (
              <ThumbDownOutlined />
            )}
          </IconButton>
        )}
      </Stack>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Enter Rejection Reason</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            placeholder="Enter reason for rejection"
            value={reasonInput}
            onChange={(e) => setReasonInput(e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSaveReason}
            variant="contained"
            disabled={!reasonInput.trim()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <div className="flex justify-end w-full p-1">
          <CircularProgress size={20} />
        </div>
      )}

      {selectedReason && !isLoading && (
        <div className="mt-1 text-right text-[#d32f2f] pr-1">
          {selectedReason}
        </div>
      )}
    </div>
  );
};

export default SourceFeedback;
