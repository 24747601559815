// @flow

import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import enLocale from 'date-fns/locale/en-US';
import { format, parseISO, isValid } from 'date-fns';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { showToastMsg } from 'features/toast-message-slice';
import ChartFilesUploadModal from './ChartFilesUploadModal';
import ChartFilesTable from './ChartFilesTable';
import { useGetPatientChartFilesQuery } from 'api/api';

type Props = {
  patientId: string
};

const ChartFiles = (props: Props) => {
  const { patientId } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const params = useMemo(
    () =>
      Array.from(searchParams).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
      ),
    [searchParams]
  );

  const { data, error, isLoading, refetch } = useGetPatientChartFilesQuery({
    patientId,
    searchString: searchParams.toString() + `&page=${page}&limit=${limit}`
  });

  const [hasFiles, setHasFiles] = useState(false);

  useEffect(() => {
    if (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: 'Failed to fetch chart files',
          level: 'error',
          duration: 5000
        })
      );
    }

    if (data && data.data) {
      setHasFiles(data.data.length > 0);
    }
  }, [data, error, dispatch]);

  const files = data?.data || [];
  const total = data?.total || 0;

  const handleOpenModal = () => setOpenUploadModal(true);
  const handleCloseModal = () => setOpenUploadModal(false);

  const handleFilter = (filterParams) => {
    setSearchParams((prev) => {
      Object.keys(filterParams).forEach((key) => {
        prev.set(key, filterParams[key]);
      });
      return prev;
    });
  };

  const reset = () => {
    setSearchParams({});
  };

  const handleServiceFromPickerChange = (value) => {
    if (!isValid(value)) return;
    const serviceFrom = format(value, 'yyyy-MM-dd');
    handleFilter({ serviceFrom });
  };

  const handleServiceToPickerChange = (value) => {
    if (!isValid(value)) return;
    const serviceTo = format(value, 'yyyy-MM-dd');
    handleFilter({ serviceTo });
  };

  const handleCreatedDtPickerChange = (paramName) => (value) => {
    if (!isValid(value)) return;
    const formattedDate = format(value, 'yyyy-MM-dd');
    handleFilter({ [paramName]: formattedDate });
  };

  const handleSelectChange = (event) => {
    handleFilter({ [event.target.name]: event.target.value });
  };

  type SelectFilterProps = {
    name: string,
    label: string,
    array: Array<string>
  };

  const SelectFilter = ({ name, label, array }: SelectFilterProps) => (
    <>
      {array.length > 0 && (
        <Grid item sm={6} md={3}>
          <TextField
            className="w-full"
            name={name}
            select
            value={params[name] || ''}
            label={label}
            onChange={handleSelectChange}
          >
            {array.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </>
  );

  const documentTypes = useMemo(
    () =>
      hasFiles ? [...new Set(files.map((file) => file.documentType))] : [],
    [hasFiles, files]
  );

  const documentTitles = useMemo(
    () =>
      hasFiles ? [...new Set(files.map((file) => file.documentTitle))] : [],
    [hasFiles, files]
  );

  const encounterTypes = useMemo(
    () =>
      hasFiles ? [...new Set(files.map((file) => file.encounterType))] : [],
    [hasFiles, files]
  );

  const encounterOrganizations = useMemo(
    () =>
      hasFiles
        ? [...new Set(files.map((file) => file.encounterOrganization || []))]
        : [],
    [hasFiles, files]
  );

  const organizations = useMemo(
    () =>
      hasFiles ? [...new Set(files.map((file) => file.organization))] : [],
    [hasFiles, files]
  );

  const authorSoftwares = useMemo(
    () =>
      hasFiles ? [...new Set(files.map((file) => file.authorSoftware))] : [],
    [hasFiles, files]
  );

  const sourceNames = useMemo(
    () => (hasFiles ? [...new Set(files.map((file) => file.sourceName))] : []),
    [hasFiles, files]
  );

  const ServiceDateFromFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Service Date From'}
        value={params.serviceFrom ? parseISO(params.serviceFrom) : null}
        onChange={handleServiceFromPickerChange}
        renderInput={(params) => (
          <TextField
            {...params}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={isLoading}
      />
    </LocalizationProvider>
  );

  const ServiceDateToFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Service Date Until'}
        value={params.serviceTo ? parseISO(params.serviceTo) : null}
        onChange={handleServiceToPickerChange}
        renderInput={(params) => (
          <TextField
            {...params}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={isLoading}
      />
    </LocalizationProvider>
  );

  const DateAddedFromFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Date Added From'}
        value={params.createdFrom ? parseISO(params.createdFrom) : null}
        onChange={handleCreatedDtPickerChange('createdFrom')}
        renderInput={(rParams) => (
          <TextField
            {...rParams}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={isLoading}
      />
    </LocalizationProvider>
  );

  const DateAddedToFilter = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <DatePicker
        disableFuture
        label={'Date Added Until'}
        value={params.createdTo ? parseISO(params.createdTo) : null}
        onChange={handleCreatedDtPickerChange('createdTo')}
        renderInput={(rParams) => (
          <TextField
            {...rParams}
            type="outlined"
            error={false}
            className="w-full"
          />
        )}
        disabled={isLoading}
      />
    </LocalizationProvider>
  );

  return (
    <Container
      maxWidth="none"
      className={`${
        user && user.role !== 'payer' ? '!pl-[86px] sm:!pl-[193px]' : ''
      } mt-5`}
    >
      <Grid container spacing={2}>
        <Grid item sm={6} md={3}>
          <ServiceDateFromFilter />
        </Grid>
        <Grid item sm={6} md={3}>
          <ServiceDateToFilter />
        </Grid>
        <SelectFilter
          name="documentType"
          label="Document Type"
          array={documentTypes}
        />
        <SelectFilter
          name="documentTitle"
          label="Document Title"
          array={documentTitles}
        />
        <SelectFilter
          name="encounterType"
          label="Encounter Type"
          array={encounterTypes}
        />
        <SelectFilter
          name="encounterOrganization"
          label="Encounter Organization"
          array={encounterOrganizations}
        />
        <SelectFilter
          name="organization"
          label="Organization Name"
          array={organizations}
        />
        <SelectFilter
          name="sourceName"
          label="Source Name"
          array={sourceNames}
        />
        <SelectFilter
          name="authorSoftware"
          label="Author Software"
          array={authorSoftwares}
        />
        <Grid item sm={6} md={3}>
          <DateAddedFromFilter />
        </Grid>
        <Grid item sm={6} md={3}>
          <DateAddedToFilter />
        </Grid>
      </Grid>
      <div className="flex items-center mt-3 mr-3">
        <Typography variant="body1">
          {total} Chart file{total === 1 ? '' : 's'} found
        </Typography>
        <Button
          className="!ml-2"
          color="secondary"
          variant="outlined"
          startIcon={<RestartAltIcon />}
          onClick={reset}
          disabled={isLoading}
        >
          Reset Filters
        </Button>
        <Button
          className="!ml-2"
          color="primary"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={handleOpenModal}
          disabled={isLoading}
        >
          Upload Chart Files
        </Button>
      </div>
      <ChartFilesUploadModal
        patientId={patientId}
        refetch={refetch}
        open={openUploadModal}
        onClose={handleCloseModal}
      />
      {isLoading ? (
        <div className="justify-center w-full">
          <CircularProgress />
        </div>
      ) : (
        <ChartFilesTable
          files={files}
          total={total}
          handleFilter={handleFilter}
          patientId={patientId}
          page={page}
          limit={limit}
          onPageChange={setPage}
          onLimitChange={setLimit}
        />
      )}
    </Container>
  );
};

export default ChartFiles;
